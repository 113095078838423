import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useRecoilState } from "recoil";
import { globalModalState } from "../state/globalModalState";
import { appIntegrationState } from "../state/appIntegrationState";
import { AppIntegrationApplicationType } from "../core/types/appIntegrationApplicationType";
import { useParams } from "react-router";
import appIntegrationService from "../core/services/appIntegration.service";
import { FacebookPage } from "../core/entities/facebookPage";
import { AppIntegration } from "../core/entities/appIntegration";
import useGa4Auth from "./analytics/useGa4Auth";
import AppConfig from "../config/appConfig";

const useIntegrations = () => {
  const { websiteId } = useParams();

  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );
  const { onGoogleAnalyticsLogin } = useGa4Auth(websiteId as string);

  const [showFacebookModal, setShowFacebookModal] = useState({
    open: false,
    type: AppIntegrationApplicationType.FACEBOOK,
  });
  const [facebookPages, setFacebookPages] = useState<FacebookPage[]>([]);

  const facebookLogin = async (metaType: AppIntegrationApplicationType) => {
    const windowAny: any = window;
    const FB = windowAny.FB;
    FB.login(
      function (response: any) {
        if (response.authResponse) {
          console.log(response.authResponse);

          const token = response.authResponse.accessToken;

          appIntegrationService.getFacebookPages(token).then(async (pages) => {
            console.log(pages);
            setFacebookPages(pages);
            setShowFacebookModal({
              open: true,
              type: metaType,
            });
          });
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "1023975852514225", // Replace with your actual config_id)
      }
    );
  };

  const facebookToggle = async (value: boolean) => {
    const facebookIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.FACEBOOK
    );

    if (facebookIntegration) {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(facebookIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === facebookIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    } else {
      facebookLogin(AppIntegrationApplicationType.FACEBOOK);
    }
  };

  const instagramToggle = async (value: boolean) => {
    const instagramIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.INSTAGRAM
    );

    if (instagramIntegration) {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(instagramIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === instagramIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    } else {
      facebookLogin(AppIntegrationApplicationType.INSTAGRAM);
    }
  };

  const onToggleAppIntegration = async (value: boolean) => {
    const updatedAppIntegration = appIntegrations.find(
      (a) => a.id === AppIntegrationApplicationType.EMAIL
    ) as AppIntegration;
    const filteredAppIntegrations = appIntegrations.filter(
      (a) => a.id !== updatedAppIntegration.id
    );

    setAppIntegrations([
      ...filteredAppIntegrations,
      {
        ...updatedAppIntegration,
        isEnabled: value,
      },
    ]);

    try {
      await appIntegrationService.updateAppIntegrationByWebsiteId(
        updatedAppIntegration.id,
        {
          isEnabled: value,
        }
      );
    } catch (err) {}
  };

  const linkedinToggle = async (value: boolean) => {
    const linkedinIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType ===
        AppIntegrationApplicationType.LINKEDIN
    );

    if (linkedinIntegration && linkedinIntegration.isEnabled) {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(linkedinIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === linkedinIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    } else {
      document.location.href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=77gvdtjx20456n&redirect_uri=${AppConfig.serverUrl}/app-integration%2Flinkedin%2Fauth&state=${websiteId}&scope=openid%20profile%20w_member_social%20email`;
    }
  };

  const analyticsToggle = async (value: boolean) => {
    const updatedAppIntegration = appIntegrations.find(
      (a) =>
        a.appIntegrationApplicationType ===
        AppIntegrationApplicationType.GOOGLEANALYTICS
    ) as AppIntegration;

    const filteredAppIntegrations = appIntegrations.filter(
      (a) => a.id !== updatedAppIntegration.id
    );

    setAppIntegrations([
      ...filteredAppIntegrations,
      {
        ...updatedAppIntegration,
        isEnabled: value,
      },
    ]);

    try {
      if (value) {
        onGoogleAnalyticsLogin();
      } else {
        await appIntegrationService.updateAppIntegrationByWebsiteId(
          updatedAppIntegration.id,
          {
            isEnabled: value,
          }
        );
      }
    } catch (err) {}
  };

  const xToggle = async (value: boolean) => {
    const xIntegration = appIntegrations.find(
      (i) => i.appIntegrationApplicationType === AppIntegrationApplicationType.X
    );

    if (xIntegration && xIntegration.isEnabled) {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(xIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === xIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    } else {
      document.location.href = `https://twitter.com/i/oauth2/authorize?response_type=code&client_id=X0EwVExrSHprYW4tSms5a1V5enQ6MTpjaQ&redirect_uri=${AppConfig.serverUrl}/app-integration%2Fx%2Fauth&scope=tweet.read%20users.read%20offline.access%20tweet.read&state=${websiteId}&code_challenge=challenge&code_challenge_method=plain`;
    }
  };
  const tiktokToggle = async (value: boolean) => {
    const tiktokIntegration = appIntegrations.find(
      (i) =>
        i.appIntegrationApplicationType === AppIntegrationApplicationType.TIKTOK
    );

    if (tiktokIntegration && tiktokIntegration.isEnabled) {
      appIntegrationService
        .updateAppIntegrationByWebsiteId(tiktokIntegration.id, {
          isEnabled: value,
        })
        .then(() => {
          setAppIntegrations(
            appIntegrations.map((i) =>
              i.id === tiktokIntegration.id ? { ...i, isEnabled: value } : i
            )
          );
        });
    } else {
      document.location.href = `https://www.tiktok.com/v2/auth/authorize?client_key=sbaw320ipnpudbnapk&response_type=code&scope=user.info.profile,video.upload,video.publish&redirect_uri=${
        AppConfig.devMode
          ? `https://aefb-85-64-146-79.ngrok-free.app/app-integration/tiktok/auth`
          : AppConfig.serverUrl + "/app-integration/tiktok/auth"
      }&state=${websiteId}`;
    }
  };

  const getIntegrationToggle: Partial<
    Record<AppIntegrationApplicationType, (value: boolean) => {}>
  > = {
    [AppIntegrationApplicationType.FACEBOOK]: facebookToggle,
    [AppIntegrationApplicationType.LINKEDIN]: linkedinToggle,
    [AppIntegrationApplicationType.GOOGLEANALYTICS]: analyticsToggle,
    [AppIntegrationApplicationType.X]: xToggle,
    [AppIntegrationApplicationType.EMAIL]: onToggleAppIntegration,
    [AppIntegrationApplicationType.TIKTOK]: tiktokToggle,
    [AppIntegrationApplicationType.INSTAGRAM]: instagramToggle,
  };

  return {
    getIntegrationToggle,
    showFacebookModal,
    facebookPages,
    setShowFacebookModal,
  };
};

export default useIntegrations;
