import Route from "./core/types/route";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage1";
import Overview from "./pages/websitePages/Overview";
import Keywords from "./pages/websitePages/KeywordsPage";
import DocsPage from "./pages/websitePages/Docs";
import HistoryPage from "./pages/websitePages/HistoryPage";
import { UserRole } from "./core/types/userRole";
import WriterSettingsPage from "./pages/websitePages/WriterSettingsPage";
import IntegrationPage from "./pages/websitePages/IntegrationsPage";
import CreateWebsitePage from "./pages/CreateWebsitePage";
import ArticleBuilderPage from "./pages/websitePages/ArticleBuilderPage";
import Signup from "./pages/Signup";
import ResetPassword from "./pages/ResetPassword";
import UpdatePassword from "./pages/UpdatePassword";
import PluginDownloads from "./pages/PluginDownloads";
import UserPreferences from "./pages/companyPages/UserPreferences";
import SubscriptionManage from "./pages/companyPages/SubscriptionManage";
import ComingSoon from "./pages/websitePages/Analytics";
import Scheduler from "./pages/websitePages/Scheduler";
import WordpressPage from "./pages/pluginPages/WordpressPage";
import ArticleEditorPage from "./pages/articlePages/ArticleEditorPage";
import ArticleViewerPage from "./pages/articlePages/ArticleViewerPage";
import TeamPage from "./pages/companyPages/TeamPage";
import CustomPluginPage from "./pages/pluginPages/CustomPluginPage";
import Templates from "./pages/websitePages/Templates";
import SuperAdminAllUsersPage from "./pages/adminPages/SuperAdminPage";
import PerferenceTabsNav from "./components/website/PerferenceTabsNav";
import AutomaticWriterTabsNav from "./components/website/AutomaticWriterTabsNav";
import VerifyEmailPage from "./pages/VerifyEmailPage";
import AfterLoginPage from "./pages/AfterLoginPage";
import PublishSettingsPage from "./pages/websitePages/PublishSettingsPage";
import BusinessInfo from "./pages/websitePages/BusinessInfo";
import ErrorPage from "./pages/ErrorPage";
import AfterPaymentPage from "./pages/AfterPaymentPage";
import TopicGeneratorPage from "./pages/freeTools/TopicGeneratorPage";
import DocumentPage from "./pages/documentPage";
import ArticlesPage from "./pages/websitePages/Articles";
import CalendarPage from "./pages/CalendarPage";
import FeedPage from "./pages/FeedPage";
import Audience from "./pages/websitePages/Audience";
import UploadFilesPage from "./pages/mobile/UploadFilesPage";
import ChooseBusinessPage from "./pages/mobile/ChooseBusinessPage";

const routes: Route[] = [
  {
    path: "/login",
    Page: <LoginPage />,
    displayName: "התחברות",
  },
  {
    path: "/signup",
    Page: <Signup />,
    displayName: "הרשמה",
  },
  {
    path: "/complete-signup",
    Page: <VerifyEmailPage />,
    displayName: "אימות כתובת אימייל",
    hideGlobalLayout: true,
  },
  {
    path: "/reset-password",
    Page: <ResetPassword />,
    displayName: "איפוס סיסמה",
  },
  {
    path: "/update-password",
    Page: <UpdatePassword />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "עדכון סיסמה",
  },
  {
    path: "/company/new",
    Page: <LoginPage />,
  },
  {
    path: "/",
    Page: <AfterLoginPage />,
    hideGlobalLayout: true,
    isProtected: true,
  },
  {
    path: "/websites",
    Page: <Homepage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "האתרים שלי",
  },
  {
    path: "/support",
    Page: <p>עמוד תמיכה</p>,
    isProtected: true,
    displayName: "תמיכה",
  },
  {
    path: "/websites/:websiteId/team",
    Page: <TeamPage />,
    isProtected: true,
    displayName: "צוות",
  },
  {
    path: "/websites/:websiteId/user-preferences/account-settings",
    Page: <UserPreferences />,
    isProtected: true,
    displayName: "העדפות משתמש",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/user-preferences/subscription-manage",
    Page: <SubscriptionManage />,
    isProtected: true,
    displayName: "ניהול מנויים",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/user-preferences/billing-and-payment",
    Page: <SubscriptionManage />,
    isProtected: true,
    displayName: "מידע חיוב ושיטות תשלום",
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/websites/:websiteId/",
    Page: <Overview />,
    isProtected: true,
    displayName: "סקירת האתר",
  },
  {
    path: "/websites/:websiteId/analytics",
    Page: <ComingSoon />,
    isProtected: true,
    displayName: "בקרוב",
  },
  {
    path: "/websites/:websiteId/calendar",
    Page: <CalendarPage />,
    isProtected: true,
    displayName: "יומן",
  },
  {
    path: "/websites/:websiteId/feed",
    Page: <FeedPage />,
    isProtected: true,
    displayName: "פיד",
  },
  {
    path: "/websites/:websiteId/autowriter/keywords",
    Page: <Keywords />,
    isProtected: true,
    displayName: "מילות מפתח לאתר",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/writer-settings",
    Page: <WriterSettingsPage />,
    isProtected: true,
    displayName: "הגדרות כותב",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/publish-settings",
    Page: <PublishSettingsPage />,
    isProtected: true,
    displayName: "הגדרות פרסום",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/autowriter/templates",
    Page: <Templates />,
    isProtected: true,
    displayName: "תבניות לכותב",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/integrations",
    Page: <IntegrationPage />,
    isProtected: true,
    displayName: "אינטגרציות האתר",
  },
  {
    path: "/websites/:websiteId/business-info",
    Page: <BusinessInfo />,
    isProtected: true,
    displayName: "מידע עסקי",
  },
  {
    path: "/websites/:websiteId/audience-settings",
    Page: <Audience />,
    isProtected: true,
    displayName: "קהלי יעד",
  },
  {
    path: "/websites/:websiteId/autowriter/scheduler",
    Page: <Scheduler />,
    isProtected: true,
    displayName: "מתזמן לכותב",
    subMenu: <AutomaticWriterTabsNav />,
  },
  {
    path: "/websites/:websiteId/docs",
    Page: <DocsPage />,
    isProtected: true,
    displayName: "המסמכים שלי",
  },
  {
    path: "/websites/:websiteId/articles",
    Page: <ArticlesPage />,
    isProtected: true,
    displayName: "המאמרים שלי",
  },
  {
    path: "/websites/builder",
    Page: <ArticleBuilderPage />,
    isProtected: true,
  },
  {
    path: "/websites/:websiteId/history",
    Page: <HistoryPage />,
    isProtected: true,
    role: UserRole.SUPER_ADMIN,
    displayName: "היסטוריית הכותב",
  },
  {
    path: "/websites/:websiteId/articles/:articleId",
    Page: <ArticleViewerPage />,
    isProtected: false,
    hideGlobalLayout: true,
    displayName: "תצוגה מקדימה של מאמר",
  },
  {
    path: "/websites/:websiteId/articles/:articleId/edit",
    Page: <ArticleEditorPage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "עריכת מאמר",
  },
  {
    path: "/websites/:websiteId/document/:documentId",
    Page: <DocumentPage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "מסמך",
  },
  {
    path: "/websites/new",
    Page: <CreateWebsitePage />,
    isProtected: true,
    hideGlobalLayout: true,
    displayName: "חבר את האתר שלך",
  },
  {
    path: "/websites/:websiteId/plugins",
    Page: <PluginDownloads />,
    isProtected: true,
    hideGlobalLayout: false,
  },
  {
    path: "/websites/:websiteId/plugins/wordpress",
    Page: <WordpressPage />,
    isProtected: true,
    hideGlobalLayout: false,
  },
  {
    path: "/websites/:websiteId/cms/custom",
    Page: <CustomPluginPage />,
    isProtected: true,
  },
  {
    path: "/websites/:websiteId/user-preferences/admin/users",
    Page: <SuperAdminAllUsersPage />,
    isProtected: true,
    role: UserRole.SUPER_ADMIN,
    subMenu: <PerferenceTabsNav />,
  },
  {
    path: "/payment/:status",
    Page: <AfterPaymentPage />,
    displayName: "תשלום",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/tools/topics",
    Page: <TopicGeneratorPage />,
    displayName: "צור נושאים בחינם",
    isProtected: false,
    hideGlobalLayout: true,
  },
  {
    path: "/mobile",
    Page: <ChooseBusinessPage />,
    displayName: "Mobile",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/websites/:websiteId/mobile/attachments",
    Page: <UploadFilesPage />,
    displayName: "העלאת קבצים",
    isProtected: true,
    hideGlobalLayout: true,
  },
  {
    path: "/error",
    Page: <ErrorPage />,
    displayName: "שגיאה",
  },
  {
    path: "*",
    Page: <h1>Oops, this is 404 :(</h1>,
    displayName: "404",
  },
];

export default routes;
