import { useDrag, useDragDropManager } from "react-dnd";
import PostCalendarEvent from "../post/PostCalendarEvent";

export const DraggablePost = ({ post }: { post: any }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: "POST",
    item: { post },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  return (
    <div
      className="w-full"
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <PostCalendarEvent post={post} />
    </div>
  );
};
