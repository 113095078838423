import React, { useCallback, useRef, useState } from "react";
import { TileContentFunc, Value } from "react-calendar/dist/cjs/shared/types";

import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../state/postState";
import { useParams } from "react-router";

import postService from "../core/services/post.service";

import { PostEntity, PostEntityType } from "neword-core";
import DroppableTile from "../components/calendar/droppableTile";
import { DraggablePost } from "../components/calendar/draggablePost";
import EmptyTile from "../components/calendar/emptyTile";
import { CalendarStyled } from "../components/calendar/calendarStyled";

import CalendarMenu from "../components/calendar/CalendarMenu";
import AddManualContent from "../components/modals/AddManualContent";

function MyCalendar() {
  const { websiteId } = useParams();

  const [showModal, setShowModal] = useState(false); // Modal visibility state

  const toggleModal = () => setShowModal((prev) => !prev); // Function to toggle modal visibility

  const [posts, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [filterPostType, setFilterPostType] = useState<PostEntityType[]>(
    Object.values(PostEntityType) as PostEntityType[]
  );
  const [value, setValue] = useState<Value>(new Date());

  // Function to check if a date has an event
  const getEventsForDate = (date: Date) => {
    return posts.filter(
      (post) =>
        new Date(post.scheduleDate).toDateString() === date.toDateString() &&
        (filterPostType.length === 0 || filterPostType.includes(post.type))
    );
  };

  const combineDateAndTime = (dateObj: Date, timeObj: Date) => {
    console.log(timeObj);

    // Extract the date parts (year, month, day) from the first object
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth(); // Month is zero-based
    const day = dateObj.getDate();

    const test = new Date(timeObj);
    // Extract the time parts (hours, minutes, seconds, milliseconds) from the second object
    const hours = test.getHours();
    const minutes = test.getMinutes();
    const seconds = test.getSeconds();

    // Create a new Date object with the date from dateObj and the time from timeObj
    return new Date(year, month, day, hours, minutes, seconds);
  };

  // Function to handle event drop
  const handleEventDrop = (post: PostEntity, newDate: Date) => {
    // Logic to update the post's scheduleDate to the new date

    postService.updatePost({ ...post, scheduleDate: newDate }).then(() =>
      setPosts((oldPosts) =>
        oldPosts.map((p) =>
          p.id === post.id
            ? {
                ...p,
                scheduleDate: combineDateAndTime(newDate, p.scheduleDate),
              }
            : p
        )
      )
    );
    // Here, you should update the state or make an API call to persist the change
    // updateRecoilState(updatedPosts); // Assuming you'll update the Recoil state here
  };

  const tileContent: TileContentFunc = useCallback(
    ({ date, view }) => {
      const today = new Date();

      if (view === "month") {
        const events = getEventsForDate(date);

        if (events.length > 0) {
          return (
            <>
              {events.map((post) => (
                <DraggablePost
                  key={post.id}
                  post={{ ...post, scheduleDate: new Date(post.scheduleDate) }}
                />
              ))}
            </>
          );
        }
      }

      // Check if the date is in the future or today
      if (date >= today || date.toDateString() === today.toDateString()) {
        return <EmptyTile date={date} />;
      }

      return null;
    },
    [posts, filterPostType]
  );

  const tileClassName = ({ date }: { date: Date }) => {
    const today = new Date();
    if (date < today && date.toDateString() !== today.toDateString()) {
      return "past-date";
    }
    return "";
  };

  return (
    <>
      <CalendarMenu
        setFilterPostType={setFilterPostType}
        filterPostType={filterPostType}
        toggleModal={toggleModal} // Pass toggleModal to child

      />
      <CalendarStyled
        onChange={(val) => setValue(val)}
        value={value}
        tileContent={(params) => (
          <DroppableTile date={params.date} onDropEvent={handleEventDrop}>
            {tileContent(params)}
          </DroppableTile>
        )}
        tileClassName={tileClassName}
        locale="he"
      />
      {showModal && <AddManualContent onHide={toggleModal} />} {/* Show modal based on state */}
      </>
  );
}

export default MyCalendar;
