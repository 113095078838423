import { useParams } from "react-router";
import TabsNav from "../core/TabsNav";
import WebsiteMenuItems from "../websites/tabsNavbar/WebsiteMenuItems";

interface Params {
  shrink?: boolean;
}

const WebsiteTabsNav: React.FC<Params> = (props) => {
  const { websiteId } = useParams();
  return (
    <>
      <TabsNav
        shrink={props.shrink}
        prefix={`/websites/${websiteId}`}
        items={WebsiteMenuItems}
        isSubMenu={false}
      />
    </>
  );
};
export default WebsiteTabsNav;
