import { InputSwitch } from "primereact/inputswitch";
import Card from "../components/common/Card";
import CardTitle from "../components/common/CardTitle";
import { AppIntegrationApplicationType } from "../core/types/appIntegrationApplicationType";
import { appIntegrationState } from "../state/appIntegrationState";
import { useRecoilState } from "recoil";
import { useParams } from "react-router";
import { ReactComponentElement, useMemo } from "react";

export const IntegrationCard = ({
  type,
  onToggle,
  Logo,
  label,
  text,
}: {
  type: AppIntegrationApplicationType;
  onToggle?: (value: boolean) => {};
  Logo: React.ComponentType<any>;
  label: string;
  text: string;
}) => {
  const { websiteId } = useParams();

  const [appIntegrations, setAppIntegrations] = useRecoilState(
    appIntegrationState(websiteId as string)
  );

  const integration = useMemo(
    () => appIntegrations.find((i) => i.appIntegrationApplicationType === type),
    [appIntegrations]
  );

  return (
    <div className="col-3 h-20">
      <Card>
        <div className="flex justify-content-between mb-3">
          <div className="flex align-items-center">
            <div style={{ height: "20px", width: "20px" }}>
              <Logo />
            </div>
            <CardTitle title={label} className="mr-3 mb-0" />
          </div>
          <div>
            <InputSwitch
              onChange={onToggle ? (e) => onToggle(!!e.value) : () => {}}
              checked={!!integration?.isEnabled}
            />
          </div>
        </div>
        <small className=""> {text} </small>
      </Card>
    </div>
  );
};
