import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  let errorText = "";

  // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate combined upload
  if (videos.length === 0 && images.length === 0) {
    errorText = "חייב מדיה אחת לפחות";
  }
  if (value.length > 10) {
    errorText = "מותר להעלות עד 10 תמונות או סרטונים בלבד";
  }

  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
        };

      // Aspect Ratio: Must be 9:16
      if (aspectRatio !== "9:16") {
        return "היחס רוחב-גובה של הווידאו חייב להיות 9:16";
      }

      // Resolution: Minimum 540x960
      const [width, height] = resolution
        .split("x")
        .map((dim) => parseInt(dim.trim(), 10));
      if (isNaN(width) || isNaN(height) || width < 540 || height < 960) {
        return "הרזולוציה של הווידאו חייבת להיות לפחות 540x960 פיקסלים";
      }

      // Frame Rate: Between 24-60 FPS
      if (frameRate < 24 || frameRate > 60) {
        return "קצב הפריימים של הווידאו חייב להיות בין 24 ל-60 FPS";
      }

      // Duration: Between 3-90 seconds
      if (duration < 3 || duration > 90) {
        return "אורך הווידאו חייב להיות בין 3 ל-90 שניות";
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution } = image.metadata;

      // Aspect Ratio: Between 4:5 and 1.91:1
      const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      const ratio = widthRatio / heightRatio;
      if (ratio < 0.8 || ratio > 1.91) {
        return "היחס רוחב-גובה של התמונה חייב להיות בין 4:5 ל-1.91:1";
      }

      // Resolution: Minimum 320px on the shorter side
      const [width, height] = resolution
        .split("x")
        .map((dim) => parseInt(dim.trim(), 10));
      if (isNaN(width) || isNaN(height) || Math.min(width, height) < 320) {
        return "הרזולוציה של התמונה חייבת להיות לפחות 320 פיקסלים בצד הקצר";
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }
  return errorText;
};
