import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import ModalHeader from "../modals/ModalHeader";

import { useCallback, useMemo, useRef, useState } from "react";
import Button from "../common/form/Button";

import { PostEntity, PostEntityType } from "neword-core";
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useController,
  useFieldArray,
  useForm,
} from "react-hook-form";

import { postTypeToForm } from "./forms/postTypeToForm";
import postService from "../../core/services/post.service";
import { useParams } from "react-router";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import { ReactComponent as RemoveIcon } from "../../assets/Icons/Remove.svg";
import { Menu } from "primereact/menu";
import { Tooltip } from "primereact/tooltip";
import { PostCardIcons } from "./postIconMappings";
import { ZPostData } from "neword-core/dist/cjs/entities/post/postData";
import IconButton from "../common/IconButton";
import { createPostDeafultParams } from "./createPostDeafultParams";
import { useRecoilState, useRecoilValue } from "recoil";
import { postsSummariesState } from "../../state/postState";
import PostDateInput from "./PostDateInput";
import { toast } from "react-toastify";
import { appIntegrationState } from "../../state/appIntegrationState";
import { postTypeToappIntegrationApplicationType } from "./postTypeToappIntegrationApplicationType";

const DialogStyled = styled(Dialog)`
  .p-dialog-content {
    padding: 0px;
    overflow: visible;
  }

  .p-dialog-header {
    padding: 0px !important;
  }

  .p-dialog-footer {
    padding: 1.5em !important;
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

interface Props {
  postDefaults?: PostEntity["data"];
  scheduleDate?: Date;
  onHide: () => void;
}

const IconWrapper = styled.div<{ isEditing: boolean }>`
  /* border: ${({ isEditing }) => (!isEditing ? "none" : "solid 2px  black")};

  border-radius: 8.5px; */
`;
const PlatformsWrapper = styled.div`
  border-left: 1px solid var(--border-color);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  padding-top: 20px;
`;

const RemoveSocial = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 15px;
  height: 15px;
  background: var(--title-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: 0;
  pointer-events: none;
`;

const PlatformIconWrapper = styled.div<{
  backgroundColor?: string;
  active?: boolean;
  dashed?: boolean;
  iconColor?: string;
}>`
  &:hover ${RemoveSocial} {
    opacity: 1;
    pointer-events: all;
  }

  &:hover {
    border-color: var(--primary-purple);

    svg path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
  position: relative;
  cursor: pointer;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  background: ${(props) =>
    props.active ? props.backgroundColor : "var(--border-color)"};
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border: ${(props) => (props.dashed ? "1px dashed lightgrey" : "none")};
  svg {
    width: 15px;
    height: 15px;
    fill: ${(props) =>
      props.iconColor
        ? props.iconColor
        : props.active
          ? "white !important"
          : "#9AA8B6 !important"};

    path {
      fill: ${(props) =>
        props.iconColor
          ? props.iconColor
          : props.active
            ? "white !important"
            : "#9AA8B6 !important"};
    }
  }
`;

const FormWrapper = styled.div`
  flex: 1;
`;

const SocialWrapper = styled.div`
  width: 80px;
`;

const AddSocialButton = styled.div`
  cursor: pointer;

  &:hover {
    background: var(--light-bg);
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-direction: row;
`;

const PublishPostModal: React.FC<Props> = ({
  onHide,
  postDefaults,
  scheduleDate,
}) => {
  const addSocialMenu = useRef<Menu>(null);
  const { websiteId } = useParams();
  const appIntegrations = useRecoilValue(
    appIntegrationState(websiteId as string)
  );
  const [isLoading, setIsLoading] = useState(false);

  const [postsSummaries, setPostsSummaries] = useRecoilState(
    postsSummariesState(websiteId as string)
  );

  const methods = useForm<
    Omit<PostEntity, "data"> & { posts: PostEntity["data"][] }
  >({
    defaultValues: {
      scheduleDate: scheduleDate,
      type:
        (postDefaults?.type as PostEntityType) || PostEntityType.FACEBOOK_POST,
      posts: [
        postDefaults || createPostDeafultParams(PostEntityType.FACEBOOK_POST),
      ],
    },
  });

  methods.getValues("scheduleDate");
  const posts = useFieldArray({ control: methods.control, name: "posts" });

  const [postType, setPostType] = useState(
    (postDefaults?.type as PostEntityType) || PostEntityType.FACEBOOK_POST
  );

  const getTypeIndex = useCallback(
    (type: PostEntityType) =>
      posts.fields.findIndex((post) => post.type === type),
    [posts]
  );

  const postIndex = useMemo(
    () => getTypeIndex(postType),
    [posts.fields, postType]
  );

  const FormComponent =
    postTypeToForm[methods.getValues(`posts.${postIndex}.type`)];

  const handleSubmit: SubmitHandler<
    Omit<PostEntity, "data"> & {
      posts: PostEntity["data"][];
    }
  > = async (values) => {
    // if (isMissingIntegration) return;

    const { posts, ...restValues } = values;
    const postsToSubmit: PostEntity[] = values.posts.map((p) => ({
      ...restValues,

      data: { ...p },
      type: p.type as PostEntityType,
    }));
    try {
      setIsLoading(true);
      await postService.createDocument(postsToSubmit, websiteId as string);
      toast("הפוסט נוצר בהצלחה", {
        type: "success",
      });
      setPostsSummaries(
        await postService.getPostsByWebsiteId(websiteId as string)
      );
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
    onHide();
  };

  const isMissingIntegration = useMemo(
    () =>
      posts.fields
        .map((p) => {
          return appIntegrations.find(
            (integration) =>
              integration.appIntegrationApplicationType ===
                postTypeToappIntegrationApplicationType(
                  p.type as PostEntityType
                ) && integration.isEnabled
          );
        })
        .includes(undefined),
    [appIntegrations, posts]
  );

  const handlePublishSubmit: SubmitHandler<
    Omit<PostEntity, "data"> & {
      posts: PostEntity["data"][];
    }
  > = useCallback(
    async (values) => {
      //if (isMissingIntegration) return;
      const { posts, ...restValues } = values;
      const postsToSubmit: PostEntity[] = values.posts.map((p) => ({
        ...restValues,

        data: { ...p },
        type: p.type as PostEntityType,
      }));
      try {
        setIsLoading(true);
        await postService.createDocument(
          postsToSubmit,
          websiteId as string,
          true
        );
        toast("הפוסט פורסם בהצלחה", {
          type: "success",
        });
        setPostsSummaries(
          await postService.getPostsByWebsiteId(websiteId as string)
        );
      } catch (err) {
      } finally {
        setIsLoading(false);
      }
      onHide();
    },
    [isMissingIntegration]
  );

  const onClickIcon = (type: PostEntityType) => {
    if (getTypeIndex(type) < 0) {
      const deafultValues = createPostDeafultParams(type);

      const newPost: PostEntity["data"] = {
        ...deafultValues,
        message: posts.fields[postIndex].message,
        imageUrls: posts.fields[postIndex].imageUrls,
      };

      posts.append(newPost);
    }
    setPostType(type);
  };

  let menuitems = Object.entries(PostCardIcons)
    .filter(
      ([key, value]) =>
        !!value.iconPlain && getTypeIndex(key as PostEntityType) < 0
    )
    .map(([key, value]) => ({
      template: () => (
        <AddSocialButton
          onClick={() => onClickIcon(key as PostEntityType)}
          className="p-3 flex gap-10  align-items-center"
        >
          <PlatformIconWrapper backgroundColor={value.color} active>
            {value.iconPlain && <value.iconPlain />}
          </PlatformIconWrapper>
          {value.socialPostingText && value.socialPostingText}
        </AddSocialButton>
      ),
    }));

  const handleRemovePost = useCallback(
    (typeToRemove: PostEntityType) => {
      const indexToRemove = getTypeIndex(typeToRemove);

      if (posts.fields.length === 1) {
        onHide();
        return;
      }

      if (typeToRemove === postType) {
        const nextPost = posts.fields.find(
          (field) => field.type !== typeToRemove
        );
        if (nextPost) {
          setPostType(nextPost.type as PostEntityType);
        }
      }

      posts.remove(indexToRemove);
    },
    [posts, postType]
  );

  return (
    <FormProvider {...methods}>
      <form>
        <DialogStyled
          header={<ModalHeader OnClose={onHide} />}
          closable={false}
          visible
          position={"center"}
          style={{
            width: "60vw",
            margin: "0",
            boxShadow: "none",

            borderLeft: "solid 1px var(--border-color)",
            borderRadius: "0px",
            maxHeight: "100%",
            padding: "0",
          }}
          onHide={onHide}
          draggable={false}
          resizable={false}
          footer={
            <div className="grid m-0">
              <ButtonsWrapper className="col-9">
                <Button
                  loading={isLoading}
                  onClick={methods.handleSubmit(handleSubmit)}
                  bgColor="purple"
                  primary
                  className="mb-0"
                >
                  תזמון פוסט
                </Button>

                <Button
                  loading={isLoading}
                  onClick={methods.handleSubmit(handlePublishSubmit)}
                  className="mb-0"
                >
                  או, פרסם עכשיו
                </Button>

                {/* {isMissingIntegration && <span>חסר אינטגרציה </span>} */}
              </ButtonsWrapper>

              <div className="col-3">
                <PostDateInput />
              </div>
            </div>
          }
        >
          <div className="flex m-0">
            <Tooltip target=".remove-icon" />
            <Menu model={menuitems} popup ref={addSocialMenu} id="socialmenu" />
            <SocialWrapper>
              <PlatformsWrapper>
                {Object.entries(PostCardIcons)
                  .filter(
                    ([key, value]) =>
                      !!value.iconPlain &&
                      getTypeIndex(key as PostEntityType) > -1
                  ) // Filter out items that don't have an iconPlain
                  .map(([key, value]) => {
                    const isActive = getTypeIndex(key as PostEntityType);

                    return (
                      <IconWrapper
                        onClick={() => onClickIcon(key as PostEntityType)}
                        isEditing={isActive === postIndex}
                      >
                        <PlatformIconWrapper
                          active={isActive === postIndex}
                          backgroundColor={value.color}
                        >
                          {isActive > -1 && (
                            <RemoveSocial
                              onClick={() =>
                                handleRemovePost(key as PostEntityType)
                              }
                              className="remove-icon"
                              data-pr-tooltip="הסר פלטפורמה מפרסום"
                              data-pr-position="right"
                            >
                              <RemoveIcon />
                            </RemoveSocial>
                          )}

                          <IconButton
                            icon={value.iconPlain && <value.iconPlain />}
                          />
                        </PlatformIconWrapper>
                      </IconWrapper>
                    );
                  })}

                <PlatformIconWrapper
                  backgroundColor="white"
                  active
                  dashed
                  iconColor="lightgrey"
                  onClick={(event) => addSocialMenu.current?.toggle(event)}
                  aria-controls="socialmenu"
                  aria-haspopup
                >
                  <PlusIcon />
                </PlatformIconWrapper>
              </PlatformsWrapper>
            </SocialWrapper>
            <Wrapper>
              {FormComponent ? (
                <FormComponent name={`posts.${postIndex}`} index={postIndex} />
              ) : (
                <div>no form</div>
              )}{" "}
            </Wrapper>
          </div>
        </DialogStyled>
      </form>
    </FormProvider>
  );
};

export default PublishPostModal;
