import styled from "styled-components";
import postService from "../../core/services/post.service";
import { AttachmentMedia, PostEntity, PostStatusType } from "neword-core";
import React, { useRef, useState, useEffect } from "react";
import { Menu } from "primereact/menu";
import { toast } from "react-toastify";
import { Theme } from "../../core/theme/theme";
import { Tooltip } from "primereact/tooltip";
import { ReactComponent as ErrorIcon } from "../../assets/Icons/Error.svg";
import { ReactComponent as ApprovedIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as RejectedIcon } from "../../assets/Icons/Rejected.svg";
import { Divider } from "primereact/divider";
import { PostCardIcons } from "./postIconMappings";
import PublishPostModal from "./PublishPostModal";
import PreviewPostModal from "./PreviewPostModal";
import { postsSummariesState } from "../../state/postState";
import { useRecoilState } from "recoil";
import { useNavigate, useParams } from "react-router";
import { MenuItem } from "primereact/menuitem";
import { Galleria } from "primereact/galleria";
import Preloader from "../common/Preloader";
import Badge from "../common/Badge";

const Wrapper = styled.div`
  display: flex;
  position: relative;

  &:hover {
    .menu-container {
      pointer-events: all;
      opacity: 1 !important;
    }
  }

  .menu-container {
    transition-duration: 0.1s;
    pointer-events: none;
    opacity: 0;
    padding: 0 7px;
    position: absolute;
    z-index: 9;
    width: max-content;
  }
`;
const MenuStyled = styled(Menu)`
  .p-menu {
  }
  .p-menuitem-icon {
    margin-right: 0px !important;
    margin-left: 10px;
  }
`;

const TopWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 10px;
  width: 100%;
`;

const PlatformsWrapper = styled.div`
  display: flex;
  gap: 5px;

  svg {
    width: 12px;
    height: 12px;
  }
  .icon {
    width: 10px;
    height: 10px;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  /* img {
    border-radius: 6px;
    width: 100%;
    height: 80px;
    object-fit: cover;
  } */
`;

const PostText = styled.div`
  font-size: 13px;
  color: var(--text-color);
  margin-right: 10px;
  text-align: start;
  letter-spacing: -0.2px;
`;

const Status = styled.span<{ draft?: boolean }>`
  color: ${(props) => (props.draft ? "var(--yellow)" : "var(--success)")};
  font-weight: bold;
  font-size: 0.8em;
`;

const TileWrapper = styled.div<{ imageUrl?: string }>`
  cursor: pointer;
  background: white;
  margin-top: 10px;
  border: solid 1px var(--border-color);
  color: ${Theme.colors.text};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: ${(props) => `url(${props.imageUrl})`};
  background-size: cover;
  background-position: center;
  border-radius: 5px;
  font-family: "Open Sans", "open sans Hebrew";
  width: 100%; /* Allow the tile to take the full width */
  padding: 5px; /* Add some padding */
  box-sizing: border-box;
  position: relative;

  &:hover {
    border: solid 1px var(--primary-purple);

    .menu-container {
      pointer-events: all;
      opacity: 1;
    }
  }
`;

const IconWrapper = styled.div`
  width: 13px;
  height: 13px;

  svg {
    width: 13px;
    height: 13px;
  }
`;

const TimeWrapper = styled.div`
  font-size: 0.8em;
  font-weight: bold;
  color: var(--title-color);
`;

const PostImage = styled.img`
  /* position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; */
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 8px;
`;

const GalleriaStyled = styled(Galleria)`
  width: 60px;
  ul {
    line-height: 0;
  }
  .p-galleria-indicator button {
    width: 0.5rem !important;
    height: 0.1rem !important;
  }
  .p-galleria-indicators {
    padding: 0 !important;
  }

  .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background-color: var(--primary-purple);
  }
`;

const BadgeStyled = styled(Badge)`
  svg {
    width: 8px !important;
    height: 8px !important;
  }
`;

const PostCalendarEvent = ({ post }: { post: PostEntity }) => {
  const menuRef = useRef<Menu>(null);
  const tileRef = useRef<HTMLDivElement>(null);
  const { websiteId } = useParams();

  const [_, setPosts] = useRecoilState(
    postsSummariesState(websiteId as string)
  );
  const [showPreviewPostModal, setShowPreviewPostModal] = useState(false);
  const [isRightAligned, setIsRightAligned] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const handlePositionCheck = () => {
      if (tileRef.current) {
        const rect = tileRef.current.getBoundingClientRect();
        setIsRightAligned(window.innerWidth - rect.right < 400);
      }
    };

    handlePositionCheck();
    window.addEventListener("resize", handlePositionCheck);
    return () => {
      window.removeEventListener("resize", handlePositionCheck);
    };
  }, []);

  const menuItems: MenuItem[] = [
    //post.status !== PostStatusType.NO_INTEGRATION
    //?
    {
      label: "אפשרויות מהירות",
      items: [
        {
          label: "עריכת פוסט",
          icon: "pi pi-pencil",
          command: () => {
            setShowPreviewPostModal(true);
          },
        },
        post.status !== PostStatusType.APPROVED
          ? {
              label: "אישור פוסט",
              icon: "pi pi-check",
              command: () => {
                postService
                  .updatePostStatus(post.id, PostStatusType.APPROVED)
                  .then((newPost) => {
                    setPosts((oldPosts) =>
                      oldPosts.map((p) => (p.id === post.id ? newPost : p))
                    );
                    toast.success("הפוסט אושר בהצלחה");
                  });
              },
            }
          : {
              label: "ביטול פוסט",
              icon: "pi pi-times",
              command: () => {
                postService
                  .updatePostStatus(post.id, PostStatusType.REJECTED)
                  .then((newPost) => {
                    setPosts((oldPosts) =>
                      oldPosts.map((p) => (p.id === post.id ? newPost : p))
                    );
                    toast.success("הפוסט בוטל בהצלחה");
                  });
              },
            },
        post.status !== PostStatusType.PUBLISHED
          ? {
              label: "פרסם עכשיו",
              icon: "pi pi-upload",
              command: () => {
                const loadingToastId = toast.loading("מפרסם את הפוסט...");

                postService
                  .publishPost(post.id)
                  .then((newPost) => {
                    setPosts((oldPosts) =>
                      oldPosts.map((p) => (p.id === post.id ? newPost : p))
                    );
                    toast.dismiss(loadingToastId); // Dismiss the loading toast
                    toast.success("הפוסט פורסם בהצלחה");
                  })
                  .catch(() => {
                    toast.dismiss(loadingToastId); // Ensure the loading toast is dismissed on error
                    toast.error("שגיאה בפרסום הפוסט");
                  });
              },
            }
          : {
              label: "צפה בפוסט",
              icon: "pi pi-eye",
              command: () => {
                post.externalLink && window.open(post.externalLink, "_blank");
              },
            },
        {
          label: "הסתר מהלקוחות",
          icon: "pi pi-eye-slash",
        },
      ],
    },
    // : {
    //     label: " חבר אינטגרציה",
    //     items: [
    //       {
    //         label: "עבור לעמוד אינטגרציות",
    //         icon: "pi pi-external-link              ",
    //         command: () => navigate(`/websites/${websiteId}/integrations`),
    //       },
    //     ],
    //   },
  ];

  return (
    <Wrapper className="w-full realtive">
      <TileWrapper ref={tileRef} onClick={() => setShowPreviewPostModal(true)}>
        <TopWrapper className="mb-2">
          <div className="flex gap-10 align-items-center">
            <Tooltip target=".fail-icon" />
            {post.status === PostStatusType.PUBLISH_FAILED && (
              <BadgeStyled
                bgColor="title"
                textColor="white"
                className="fail-icon"
                data-pr-tooltip="הפרסום נכשל, יש לבדוק שכל האינטגרציות מחוברות!"
                data-pr-position="top"
                icon={<ErrorIcon />}
              >
                הפרסום נכשל
              </BadgeStyled>
            )}
            <Tooltip target=".publish-icon" />
            {post.status === PostStatusType.PUBLISHED && (
              <BadgeStyled
                bgColor="purple"
                textColor="white"
                className="publish-icon"
                data-pr-tooltip="הפוסט פורסם"
                data-pr-position="top"
                icon={<ApprovedIcon />}
              >
                פורסם
              </BadgeStyled>
            )}
            {post.status === PostStatusType.APPROVED && (
              <BadgeStyled
                bgColor="success"
                textColor="white"
                className="approved-icon"
                data-pr-tooltip="הפוסט אושר"
                data-pr-position="top"
                icon={<ApprovedIcon />}
              >
                מאושר
              </BadgeStyled>
            )}

            <Tooltip target=".error-icon" />
            {post.status === PostStatusType.PENDING && (
              <BadgeStyled
                bgColor="yellow"
                textColor="title"
                className="error-icon"
                data-pr-tooltip="יש לאשר את הפוסט על מנת שיתפרסם בפלטפורמה"
                data-pr-position="top"
                icon={<ErrorIcon />}
              >
                ממתין לאישור
              </BadgeStyled>
            )}
            <Tooltip target=".cancel-icon" />
            {post.status === PostStatusType.REJECTED && (
              <BadgeStyled
                bgColor="danger"
                textColor="white"
                className="cancel-icon"
                data-pr-tooltip="הפוסט בוטל"
                data-pr-position="top"
                icon={<RejectedIcon />}
              >
                בוטל
              </BadgeStyled>
            )}
          </div>

          <div className="flex align-items-center gap-10">
            <TimeWrapper>
              {post.scheduleDate.getHours().toString().padStart(2, "0") +
                ":" +
                post.scheduleDate.getMinutes().toString().padStart(2, "0")}
            </TimeWrapper>
            <PlatformsWrapper>
              {[PostCardIcons[post.type].icon].map(
                (PlatformIcon, iconIndex) => PlatformIcon
              )}
            </PlatformsWrapper>
          </div>
        </TopWrapper>
        <Divider className="m-0 mb-2" />
        <div className="flex w-full">
          {post.data.imageUrls && (
            <GalleriaStyled
              changeItemOnIndicatorHover
              value={post.data.imageUrls}
              numVisible={1}
              autoPlay
              showThumbnails={false}
              showIndicators
              item={(item: AttachmentMedia) => {
                return item.metadata.type === "VIDEO" ? (
                  <video
                    src={item.url}
                    autoPlay
                    loop
                    muted
                    style={{
                      width: "60px",
                      aspectRatio: "9/16",
                      borderRadius: "8px",
                    }}
                  />
                ) : (
                  <PostImage src={item.url} />
                );
              }}
            />
          )}
          <PostText>
            {post?.data && post.data.message.length > 52
              ? post.data.message.substring(0, 52) + "..."
              : post.data.message || ""}
          </PostText>
        </div>
      </TileWrapper>
      <div
        className="menu-container"
        style={{
          left: isRightAligned ? "auto" : "100%",
          right: isRightAligned ? "100%" : "auto",
        }}
      >
        <MenuStyled model={menuItems} />
      </div>
      {showPreviewPostModal && (
        <React.Suspense fallback={<Preloader />}>
          <PreviewPostModal
            post={post}
            onHide={() => setShowPreviewPostModal(false)}
          />
        </React.Suspense>
      )}
    </Wrapper>
  );
};

export default PostCalendarEvent;
