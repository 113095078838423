import React, { useState } from "react";
import styled, { keyframes } from "styled-components";
import { ReactComponent as DownArrow } from "../../../assets/Icons/DownArrow.svg";
import Link from "../Link";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
}

interface DropDownMenuProps {
  options: DropDownOption[];
  onSelect: (selectedOption: DropDownOption) => void;
  selectedValue?: DropDownOption | null;
}

// Keyframes for Dropdown Animation
const fadeInSlideDown = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const DropDownContainer = styled.div`
  z-index: 9999;
  position: relative;
  display: inline-block;
`;

const DropDownButton = styled.div<{ isOpen: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-radius: 4px;
  width: 200px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;

  svg {
    transition-duration: 0.2s;
    transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
    width: 12px;
    height: 12px;
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  top: 120%;
  left: 0;
  width: 100%;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  animation: ${fadeInSlideDown} 0.3s ease-out;
`;

const DropDownListItem = styled(Link)<{ isSelected: boolean }>`
  padding: 10px;
  cursor: pointer;
  background-color: ${({ isSelected }) => (isSelected ? "#f0f0f0" : "#fff")};
  display: flex;
  align-items: center;
  gap: 10px;
  .label {
    font-size: 14px !important;
    color: var(--title-color);
  }
  svg {
    width: 15px;
    height: 15px;
  }

  &:hover {
    background-color: #f9f9f9;
  }
  border-bottom: 1px solid #e0e0e0;

  &:last-child {
    border-bottom: none;
    border-radius: 0px 0px 8px 8px;
  }
  &:first-child {
    border-radius: 8px 8px 0px 0px;
  }
`;

// Main Component
const DropDownMenu: React.FC<DropDownMenuProps> = ({
  options,
  onSelect,
  selectedValue,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (option: DropDownOption) => {
    if (!option.link) {
      onSelect(option);
      setIsOpen(false);
    }
  };

  return (
    <DropDownContainer>
      <DropDownButton onClick={toggleDropdown} isOpen={isOpen}>
        {selectedValue ? selectedValue.label : "בחר תצוגה"}
        <DownArrow />
      </DropDownButton>

      {isOpen && (
        <DropDownListContainer>
          {options.map((option, index) => {
            return (
              <DropDownListItem
                isSelected={selectedValue?.label === option.label}
                className="w-full"
                path={option.link ? option.link : "#"} // Use "#" if link is not provided
                key={index}
                onClick={() => handleSelect(option)}
              >
                <>
                  {option.customTemplate ? (
                    option.customTemplate
                  ) : (
                    <>
                      <div className="flex gap-10 align-items-center">
                        {option.icon && option.icon}
                        <div className="label">{option.label}</div>
                      </div>
                    </>
                  )}
                </>
              </DropDownListItem>
            );
          })}
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};

export default DropDownMenu;
