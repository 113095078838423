import React, { useEffect, useRef, useState } from "react";
import { PostCardIcons } from "../post/postIconMappings";
import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../assets/Icons/Approved.svg";
import { ReactComponent as CalendarIcon } from "../../assets/Icons/Calendar.svg";
import { ReactComponent as FeedIcon } from "../../assets/Icons/Feed.svg";
import { ReactComponent as ExportIcon } from "../../assets/Icons/Download.svg";
import { ReactComponent as AddArticle } from "../../assets/Icons/AddArticle.svg";

import DropDownMenu from "../common/form/DropDownMenu";
import Button from "../common/form/Button";
import { PostEntityType } from "neword-core";
import AddManualContent from "../modals/AddManualContent";

interface DropDownOption {
  label: string;
  icon?: React.ReactNode | JSX.Element;
  customTemplate?: React.ReactNode;
  link?: string;
}

interface CalendarMenuProps {
  setFilterPostType: React.Dispatch<React.SetStateAction<PostEntityType[]>>;
  filterPostType: PostEntityType[];
  toggleModal: () => void;
  // Define the props for your component here
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: 20px;
  z-index: 9;
  position: sticky;
  top: 0;
  background-color: white;
  margin-top: -20px;

  gap: 10px;
  border-bottom: 1px solid var(--border-color);
`;

const TopMenu = styled.div`
  z-index: 9999999;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
`;

const IconCheckBoxWrapper = styled.div<{ active?: boolean }>`
  position: relative;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-color: ${(props) =>
    props.active ? "var(--border-color)" : "var(--border-color)"};
  svg {
    width: 20px;
    height: 20px;
  }

  cursor: pointer;
  &:hover {
    border-color: var(--primary-purple);
  }
`;

const CheckWrapper = styled.div<{ active?: boolean }>`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) =>
    props.active ? "var(--primary-purple)" : "white"};
  right: 5px;
  bottom: 5px;

  svg {
    fill: white;
    width: 6px;
    height: 6px;
  }
`;

const CalendarMenu: React.FC<CalendarMenuProps> = ({
  filterPostType,
  setFilterPostType,
  toggleModal, // Receive toggleModal as a prop
}) => {
  const SocialIcons = PostCardIcons;

  // State to track the selected option from DropDownMenu
  const [selectedOption, setSelectedOption] = useState<DropDownOption | null>(
    null
  );

 

  // Function to handle the selection of a dropdown option
  const handleSelect = (option: DropDownOption) => {
    setSelectedOption(option);
  };

  // Options to pass into DropDownMenu
  const options: DropDownOption[] = [
    {
      label: "תצוגת לוח שנה",
      icon: <CalendarIcon />,
      link: "/calendar",
    },
    {
      label: "תצוגת פיד",
      icon: <FeedIcon />,
      link: "/feed",
    },
  ];

  return (
    <>
    <Wrapper>
      <div className="grid">
        <div className="col-3 m-auto">
          <div className="flex gap-10">
            <Button
              fullWidth={false}
              icon={<AddArticle />}
              primary
              bgColor="pink"
              onClick={toggleModal} // Call the parent's toggleModal function
            >
              צור תוכן ידני
            </Button>
            <Button icon={<ExportIcon />} borderColor="title">
              ייצוא גאנט
            </Button>
          </div>
        </div>
        <div className="col-6 m-auto">
          <TopMenu>
            {Object.entries(SocialIcons).map(([key, value]) => {
              if (value.scheduleAble === true) {
                return (
                  <IconCheckBoxWrapper
                    key={key}
                    active={filterPostType.includes(key as PostEntityType)}
                    onClick={() =>
                      filterPostType.includes(key as PostEntityType)
                        ? setFilterPostType(
                            filterPostType.filter((p) => p != key)
                          )
                        : setFilterPostType((f) => [
                            ...f,
                            key as PostEntityType,
                          ])
                    }
                  >
                    <CheckWrapper
                      active={filterPostType.includes(key as PostEntityType)}
                    >
                      <CheckIcon />
                    </CheckWrapper>
                    {value.icon}
                  </IconCheckBoxWrapper>
                );
              }
              return null; // Return null if `scheduleAble` is false to avoid rendering anything
            })}
          </TopMenu>
        </div>
        <div className="col-3 m-auto">
          <DropDownMenu
            options={options}
            onSelect={handleSelect}
            selectedValue={selectedOption}
          />
        </div>
      </div>
    </Wrapper>

</>

  );
};

export default CalendarMenu;
