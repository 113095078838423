export enum DocumentEntityType {
  INSTAGRAM_CAPTION = "INSTAGRAM_CAPTION",
  INSTAGRAM_POST_IDEA = "INSTAGRAM_POST_IDEA",
  INSTAGRAM_BIO_DESCRIPTION = "INSTAGRAM_BIO_DESCRIPTION",
  INSTAGRAM_STORY_IDEA = "INSTAGRAM_STORY_IDEA",
  INSTAGRAM_REEL_IDEA = "INSTAGRAM_REEL_IDEA",
  INSTAGRAM_REEL_SCRIPT = "INSTAGRAM_REEL_SCRIPT",

  FACEBOOK_POST_CAPTION = "FACEBOOK_POST_CAPTION",
  FACEBOOK_POST_IDEA = "FACEBOOK_POST_IDEA",
  FACEBOOK_PAGE_ABOUT = "FACEBOOK_PAGE_ABOUT",

  LINKEDIN_POST_CAPTION = "LINKEDIN_POST_CAPTION",
  LINKEDIN_POST_IDEA = "LINKEDIN_POST_IDEA",
  LINKEDIN_PAGE_ABOUT = "LINKEDIN_PAGE_ABOUT",

  TWITTER_TWIT = "TWITTER_TWIT",
  THREADS_TWIT = "THREADS_TWIT",

  SMS_CONTENT = "SMS_CONTENT",
  SMS_IDEA = "SMS_IDEA",

  GOOGLE_ADS_KEYWORDS = "GOOGLE_ADS_KEYWORDS",
  GOOGLE_ADS_IDEA = "GOOGLE_ADS_IDEA",

  ARTICLE = "ARTICLE",
  WEBSITE_ABOUT = "WEBSITE_ABOUT",
  WEBSITE_FAQ = "WEBSITE_FAQ",
  WEBSITE_TESTEMONIAL = "WEBSITE_TESTEMONIAL",
  WEBSITE_PRODUCT_DESCRIPTION = "WEBSITE_PRODUCT_DESCRIPTION",

  BLOG_POST_OPINION = "BLOG_POST_OPINION",
  BLOG_POST_TIPS = "BLOG_POST_TIPS",
  BLOG_POST_GUIDE = "BLOG_POST_GUIDE",
  BLOG_POST_COMPARISON = "BLOG_POST_COMPARISON",
  BLOG_POST_CASE_STUDY = "BLOG_POST_CASE_STUDY",
  BLOG_POST_GENERAL = "BLOG_POST_GENERAL",

  EMAIL_AD_PROMO = "EMAIL_AD_PROMO",
  SOCIAL_AD = "SOCIAL_AD",
  // LINKEDIN_AD = "LINKEDIN_AD",
  SEARCH_AD = "SEARCH_AD",
  INSTAGRAM_SPONSORED_AD = "INSTAGRAM_SPONSORED_AD",
  FACEBOOK_SPONSORED_AD = "FACEBOOK_SPONSORED_AD",
  LINKEDIN_SPONSORED_AD = "LINKEDIN_SPONSORED_AD",

  EMAIL_CONTENT = "EMAIL_CONTENT",
  CART_ABANDONMENT_EMAIL = "CART_ABANDONMENT_EMAIL",
  TRAFFIC_EMAIL = "TRAFFIC_EMAIL",
  PUSH_NOTIFICATION = "PUSH_NOTIFICATION",
  NEWSLETTER_EMAIL = "NEWSLETTER_EMAIL",
  PRODUCT_UPDATE_EMAIL = "PRODUCT_UPDATE_EMAIL",
  WELCOME_EMAIL = "WELCOME_EMAIL",

  FLASH_SALE_SMS = "FLASH_SALE_SMS",
  NOTIFICATION_SMS = "NOTIFICATION_SMS",
}
