import Card from "../../components/common/Card";
import React, { useCallback, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import Button from "../../components/common/form/Button";
import { throttle } from "lodash";
import GmailLogo from "../../assets/images/integrations/gmail-logo.png";
import AnalyticsLogo from "../../assets/images/integrations/googleAnalytics-logo.png";

import styled from "styled-components";
import { Divider } from "@mui/material";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import CardTitle from "../../components/common/CardTitle";
import { cmsIntegrations } from "../../integrations/cms/cmsIntegrations";
import { publishIntegrationState } from "../../state/publishIntegrationState";
import { appIntegrationState } from "../../state/appIntegrationState";
import { AppIntegrationApplicationType } from "../../core/types/appIntegrationApplicationType";
import { AppIntegration } from "../../core/entities/appIntegration";
import appIntegrationService from "../../core/services/appIntegration.service";
import PageTitle from "../../components/common/PageTitle";
import CMSConfigurationModal from "../../components/modals/CMSConfigurationModal";
import Preloader from "../../components/common/Preloader";
import { Tooltip } from "primereact/tooltip";
import { InputSwitch } from "primereact/inputswitch";
import { IntegrationType } from "../../core/types/integrationType";
import useNavigator from "../../hooks/useNavigator";
import useGa4Auth from "../../hooks/analytics/useGa4Auth";
import { FacebookPage } from "../../core/entities/facebookPage";
import FacebookPagesModal from "../../components/common/form/FacebookPagesModal";
import AppConfig from "../../config/appConfig";
import { IntegrationCard } from "../../integrations/integrationCard";
import { integrationFactory } from "../../integrations/integrationFactory";
import useIntegrations from "../../hooks/useIntegrations";

const CardStyle = styled(Card)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  cursor: pointer;

  & img {
    width: 75px;
  }

  &:hover {
    border-color: var(--title-color);
  }

  &.disabled-card {
    pointer-events: none;
    opacity: 0.6;
  }

  &:hover .HoverArrow__linePath {
    opacity: 1;
  }

  &:hover .HoverArrow__tipPath {
    opacity: 1;
    transition: transform 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateX(3px);
  }
`;

const SectionTitle = styled.div`
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.029rem;
  margin-top: 30px;
  color: var(--title-color);
`;

const SectionSubTitle = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.029rem;
  margin-bottom: 30px;
  color: var(--text-color);
`;

const ConnectedButton = styled(Button)`
  color: var(--success);
`;

const TitleWrapper = styled.div`
  .mr-2 div:first-child {
    margin-top: 10px;
  }
`;

const ConfigurationPage: React.FC = () => {
  const { websiteId } = useParams();
  const {
    facebookPages,
    showFacebookModal,
    getIntegrationToggle,
    setShowFacebookModal,
  } = useIntegrations();
  const navigate = useNavigator();

  const publishIntegration = useRecoilValue(
    publishIntegrationState(websiteId as string)
  );

  const isCmsConnected = useMemo(
    () => !!publishIntegration,
    [publishIntegration]
  );

  const [showCMSConfigurationModal, setShowCMSConfigurationModal] =
    useState(false);

  const navigateToCms = useCallback(
    (type: IntegrationType) => {
      switch (type) {
        case IntegrationType.WORDPRESS:
          return navigate(`/plugins/wordpress`);
        case IntegrationType.CUSTOM:
          return navigate(`/cms/custom`);
        case IntegrationType.WIX:
          return (document.location.href = `https://www.wix.com/app-market/ghostwrite-ai?appIndex=0`);
      }
    },
    [navigate, publishIntegration?.type, websiteId]
  );

  const sortedCmsIntegrations = useMemo(() => {
    if (publishIntegration) {
      const filtered = cmsIntegrations.filter(
        (i) => i.type !== publishIntegration.type
      );
      const selected = cmsIntegrations.find(
        (i) => i.type === publishIntegration.type
      );
      return [selected!, ...filtered];
    }
    return cmsIntegrations;
  }, [publishIntegration]);

  return (
    <>
      <TitleWrapper className="flex justify-content-between align-items-center">
        <PageTitle
          title="אינטגרציות CMS "
          subtitle="שלב את ה-CMS המועדף עליך בקלות"
        />
      </TitleWrapper>

      <div className="grid">
        {sortedCmsIntegrations.map((integration) => {
          return (
            <div
              className="col-4"
              onClick={() => navigateToCms(integration.type)}
            >
              <CardStyle>
                <div className="flex align-items-center">
                  {integration.logo}
                  <CardTitle title={integration.name} className="mr-3 mb-0" />
                </div>
                {integration.type === publishIntegration?.type ? (
                  <ConnectedButton disabled>התחבר</ConnectedButton>
                ) : (
                  <>
                    {isCmsConnected && (
                      <Tooltip target={".add-integration-button"} />
                    )}
                    <div
                      className="add-integration-button"
                      data-pr-tooltip="ניתן לחבר רק CMS אחד"
                      data-pr-position="left"
                    >
                      <Button arrowPlacement="right" disabled={isCmsConnected}>
                        התחבר
                      </Button>
                    </div>
                  </>
                )}
              </CardStyle>
            </div>
          );
        })}
        <div
          className="col-4
        "
        ></div>
      </div>

      <Divider className="mt-5" />

      <div className="mr-2 flex justify-content-between align-items-center">
        <div>
          <SectionTitle>חיבור לאפלקציות</SectionTitle>
          <SectionSubTitle>
            הגדל את האסטרטגיה השיווקית שלך וחבר את הכלים שבהם אתה משתמש כל יום
          </SectionSubTitle>
        </div>
        <a
          href="https://ghostwrites.ai/contact-us/"
          target="_blank"
          rel="noreferrer"
        >
          <Button icon={<PlusIcon />}>בקש אינטגרציה</Button>
        </a>
      </div>
      <div className="grid">
        {Object.entries(integrationFactory).map(([key, value]) => (
          <IntegrationCard
            Logo={value.icon}
            onToggle={
              getIntegrationToggle[key as AppIntegrationApplicationType]
            }
            type={
              AppIntegrationApplicationType[
                key as AppIntegrationApplicationType
              ]
            }
            label={value.label}
            text={value.text}
          />
        ))}
      </div>

      {showCMSConfigurationModal && (
        <React.Suspense fallback={<Preloader />}>
          <CMSConfigurationModal
            onHide={() => setShowCMSConfigurationModal(false)}
          />
        </React.Suspense>
      )}

      {showFacebookModal.open && (
        <FacebookPagesModal
          isInstagram={
            showFacebookModal.type === AppIntegrationApplicationType.INSTAGRAM
          }
          onClose={() =>
            setShowFacebookModal((p) => ({ open: false, type: p.type }))
          }
          pages={facebookPages}
        />
      )}
    </>
  );
};

export default ConfigurationPage;
