import styled from "styled-components";
import { ReactComponent as Logo } from "../../assets/Logo/ColoredLogo.svg";
import Link from "../common/Link";
import { Divider } from "primereact/divider";
import Button from "../common/form/Button";
import React, { useMemo, useState } from "react";
import { ReactComponent as PlusIcon } from "../../assets/Icons/Plus.svg";
import Usage from "./Usage";
import { ReactComponent as WandIcon } from "../../assets/Icons/Wand.svg";
import { ReactComponent as CollapseIcon } from "../../assets/Icons/Collapse.svg";
import { ReactComponent as AddArticle } from "../../assets/Icons/AddArticle.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import { userState } from "../../state/userState";
import { PackageType } from "../../core/types/packageType";
import WebsiteTabsNav from "../website/WebsiteTabsNav";
import { useParams } from "react-router";
import Preloader from "../common/Preloader";
import GenerateArticleModal from "../modals/generateArtical/GenerateArticleModal";
import useNavigator from "../../hooks/useNavigator";
import { activeRunsState } from "../../state/activeRunsState";
import { ReactComponent as NewordTextLogo } from "../../assets/Icons/NewordTextLogo.svg";
import { ReactComponent as ColoredIcon } from "../../assets/Logo/ColoredIcon.svg";
import CreateDocModal from "../common/form/CreateDocModal";
import Badge from "../common/Badge";
import PublishPostModal from "../post/PublishPostModal";

interface Props {
  toggleSidebar: () => void;
}

const Wrapper = styled.div`
  background: white;
  height: 100%;
  flex-direction: column;
  border-left: 1px solid #e2e8f0;
  display: flex;
  justify-content: space-between;
`;

const RocketImage = styled.img`
  width: 35%;
  bottom: 30px;
  left: 20px;
  position: absolute;
  transition-duration: 0.2s;
`;

const LogoWrapper = styled.div`
  width: 100%;
  height: 75px;
  border-bottom: 1px solid #e2e8f0;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 20px;
    height: 20px;
  }
`;

const MenuWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  flex-direction: column;
  margin-top: 20px;
  align-items: flex-start;
  padding: 0 20px;
`;

const NewordLogo = styled(NewordTextLogo)`
  width: 80px;
  height: 80px;
`;

const PlusIconStyle = styled(PlusIcon)`
  width: 12px;
  height: 12px;
  margin-right: 15px;
`;

const ProgressWrapper = styled.div`
  span {
    font-weight: bold;
    font-size: 12px;
  }
  display: flex;
  align-items: center;
  gap: 10px;
  .MuiCircularProgress-root {
    width: 15px !important;
    height: 15px !important;
  }

  circle {
    color: var(--primary-purple);
  }
`;

const PackageWrapper = styled.div`
  padding: 0px 20px;
  padding-bottom: 20px;
`;

const CollapseWrapper = styled.div<{ shrink?: boolean }>`
  margin-right: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: solid 1px var(--border-color);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    border: solid 1px var(--title-color);
  }

  svg {
    transform: ${(props) => (props.shrink ? "rotate(180deg)" : "unset")};
    width: 18px;
    height: 18px;
  }
`;

const ButtonStyled = styled(Button)<{ shrink?: boolean }>`
  height: ${(props) => (props.shrink ? "40px" : "auto")};
  width: ${(props) => (props.shrink ? "40px" : "auto")};
  padding: ${(props) => (props.shrink ? "0" : "15px 25px")};
  justify-content: ${(props) => (props.shrink ? "center" : "unset")};
  svg {
    margin-left: ${(props) => (props.shrink ? "0px" : "10px")};
  }
`;

const SideBar: React.FC<Props> = (props) => {
  const [showCreateDocModal, setShowCreateDocModal] = useState(false);
  const [showPublishPostModal, setShowPublishPostModal] = useState(false);
  const [shrinkState, setShrinkState] = useState(false);

  const [showGenerateArticleModal, setShowGenerateArticleModal] =
    useState(false);
  const [activeRuns, setActiveRuns] = useRecoilState(activeRunsState);
  const navigate = useNavigator();

  return (
    <Wrapper>
      <div>
        <LogoWrapper>
          <Link path="/" className="flex align-items-center" global>
            <div className="ml-0">
              <Badge bgColor="white" textColor="text">
                beta
              </Badge>
            </div>
            <ColoredIcon />
          </Link>
        </LogoWrapper>
        <MenuWrapper>
          <ButtonStyled
            shrink={shrinkState}
            icon={<WandIcon />}
            primary
            bgColor="purple"
            className="w-full"
            onClick={() => setShowCreateDocModal(true)}
          >
            {!shrinkState ? " צור תוכן חדש" : ""}
          </ButtonStyled>
          <ButtonStyled
            shrink={shrinkState}
            icon={<AddArticle />}
            primary
            bgColor="pink"
            className="w-full"
            onClick={() => setShowPublishPostModal(true)}
          >
            {!shrinkState ? " צור תוכן ידני" : ""}
          </ButtonStyled>
          <Divider className="mt-0 mb-0" />

          <WebsiteTabsNav shrink={shrinkState} />
        </MenuWrapper>
      </div>
      <PackageWrapper>
        <CollapseWrapper
          shrink={shrinkState}
          onClick={() => {
            setShrinkState(!shrinkState);
            props.toggleSidebar();
          }}
        >
          <CollapseIcon />
        </CollapseWrapper>
      </PackageWrapper>

      {showCreateDocModal && (
        <CreateDocModal onHide={() => setShowCreateDocModal(false)} />
      )}

      {showPublishPostModal && (
        <React.Suspense fallback={<Preloader />}>
          <PublishPostModal onHide={() => setShowPublishPostModal(false)} />
        </React.Suspense>
      )}

      {/* <React.Suspense fallback={<Preloader />}>
        <GenerateArticleModal
          onHide={() => setShowGenerateArticleModal(false)}
          onSubmit={(workerRunId: string) => {
            setActiveRuns([...activeRuns, workerRunId]);
            setShowGenerateArticleModal(false);
            navigate(`/documents`);
          }}
        />
      </React.Suspense> */}
    </Wrapper>
  );
};

export default SideBar;
