export enum PostEntityType {
  INSTAGRAM_POST = "INSTAGRAM_POST",
  INSTAGRAM_REEL = "INSTAGRAM_REEL",
  FACEBOOK_POST = "FACEBOOK_POST",
  FACEBOOK_REEL = "FACEBOOK_REEL",

  LINKEDIN_POST = "LINKEDIN_POST",
  TWITTER_TWIT = "TWITTER_TWIT",
  THREADS_TWIT = "THREADS_TWIT",
  TIKTOK_POST = "TIKTOK_POST",
  EMAIL = "EMAIL",
  SMS = "SMS",
  ARTICLE = "ARTICLE",
  WEBSITE = "WEBSITE",
}
