import { AttachmentMedia } from "neword-core";

export default (value: AttachmentMedia[]) => {
  let errorText = "";

  // Separate videos and images
  const videos = value.filter((item) => item.metadata.type === "VIDEO");
  const images = value.filter((item) => item.metadata.type === "IMAGE");

  // Validate combined upload
  if (videos.length > 1) {
    errorText = "מותר לבחור וידאו אחד בלבד";
  }
  if (images.length > 2) {
    errorText = "מותר לבחור עד שני תמונות בלבד";
  }
  if (videos.length > 0 && images.length > 0) {
    errorText = "לא ניתן לבחור גם תמונה וגם וידאו";
  }
  // Validate Videos
  const videoErrors = videos
    .map((video) => {
      const { aspectRatio, resolution, frameRate, duration, size } =
        video.metadata as {
          type: "VIDEO";
          aspectRatio: string;
          resolution: string;
          frameRate: number;
          duration: number;
          size: number;
        };
      console.log("videos", videos);

      // Aspect Ratlogio: 1:2.4 to 2.4:1
      const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      const ratio = widthRatio / heightRatio;
      if (ratio < 1 / 2.4 || ratio > 2.4) {
        return "יחס רוחב-גובה של הווידאו חייב להיות בין 1:2.4 ל-2.4:1";
      }

      // Resolution: Minimum 256x144 pixels
      const [width, height] = resolution
        .split("x")
        .map((dim) => parseInt(dim.trim(), 10));
      if (isNaN(width) || isNaN(height) || width < 256 || height < 144) {
        return "הרזולוציה של הווידאו חייבת להיות לפחות 256x144 פיקסלים";
      }

      // Frame Rate: Up to 60 FPS
      if (frameRate > 60) {
        return "קצב הפריימים של הווידאו לא יכול לעלות על 60 FPS";
      }
      console.log(duration);

      // Duration: 3 seconds to 10 minutes
      if (duration < 3 || duration > 600) {
        return "אורך הווידאו חייב להיות בין 3 שניות ל-10 דקות";
      }

      // File Size: Maximum 5GB
      if (size > 5 * 1024 * 1024 * 1024) {
        return "גודל הקובץ של הווידאו לא יכול לעלות על 5GB";
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Validate Images
  const imageErrors = images
    .map((image) => {
      const { aspectRatio, resolution, size } = image.metadata;

      // Aspect Ratio: 1:2.4 to 2.4:1
      const [widthRatio, heightRatio] = aspectRatio.split(":").map(Number);
      const ratio = widthRatio / heightRatio;
      if (ratio < 1 / 2.4 || ratio > 2.4) {
        return "יחס רוחב-גובה של התמונה חייב להיות בין 1:2.4 ל-2.4:1";
      }

      // Resolution: Minimum 256 pixels on the shorter side
      const [width, height] = resolution
        .split("x")
        .map((dim) => parseInt(dim.trim(), 10));
      if (isNaN(width) || isNaN(height) || Math.min(width, height) < 256) {
        return "הרזולוציה של התמונה חייבת להיות לפחות 256 פיקסלים בצד הקצר";
      }

      // File Size: Maximum 5MB
      if (size > 5 * 1024 * 1024) {
        return "גודל הקובץ של התמונה לא יכול לעלות על 5MB";
      }

      return null; // No error
    })
    .filter((error) => error !== null);

  // Combine all errors
  const allErrors = [...videoErrors, ...imageErrors];
  if (allErrors.length > 0) {
    errorText = allErrors.join(". ");
  }
  console.log(allErrors);

  return errorText;
};
