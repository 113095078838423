import { Divider } from "primereact/divider";
import { InputTextarea } from "primereact/inputtextarea";
import {
  FieldErrors,
  FieldValues,
  Path,
  useFormContext,
  useWatch,
} from "react-hook-form";
import styled from "styled-components";
import AttachmentInput from "../../common/form/AttachmentInput";
import IgPreview from "../postPreviews/IgPreview";
import { useRef, useEffect, useMemo, useState } from "react";
import { ReactComponent as EmojiPickerIcon } from "../../../assets/Icons/EmojiPicker.svg";
import CardTitle from "../../common/CardTitle";
import EmojiPicker from "emoji-picker-react";
import InstagramPreview from "../../../assets/images/instagramPreview.png"; // Add Instagram preview image
import TextEditor from "../../common/publishPostModal/TextEditor";
import LocationDropdown from "../../common/publishPostModal/LocationDropdown";
import MultyAttachmentInput from "../../common/form/MultyAttachmentInput";
import { InputText } from "primereact/inputtext";
import Card from "../../common/Card";
import { Avatar } from "primereact/avatar";
import Button from "../../common/form/Button";
import { Messages } from "primereact/messages";
import { Message } from "primereact/message";
import instagramPostMediaValidation from "./mediaValdiation/instagramPostMediaValidation";

const LabelStyled = styled.div`
  font-size: 12px;
  font-weight: 600;
  color: var(--text-color);
  padding-bottom: 10px;
`;

const ImageWrapper = styled.div`
  background: #fcf6f7;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
  }
`;

const PostButton = styled(Button)`
  margin-top: 10px;
  padding: 10px 20px;
  font-size: 12px;
  margin-right: auto;
`;

const SingleCommentWrapper = styled(Card)`
  display: flex;
  flex-direction: start;
  padding: 15px;
  margin-top: 20px;
  flex-direction: column;
  gap: 10px;
`;

const CommentSection = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;
const AvatarStyled = styled(Avatar)`
  background-color: var(--yellow);
`;

const CommentUser = styled.div`
  font-size: 1em;
  font-weight: 500;
`;

const CommentDate = styled.div`
  font-size: 0.875em;
  color: #606770;
`;

const Comment = styled.div`
  font-size: 1em;
  color: #1c1e21;
  text-align: start;
  line-height: 1.5;
`;

const CommentInputWrapper = styled(Card)``;

const CommentInputCollapsed = styled(InputText)`
  width: 100%;
  border-radius: 8px;
  border: none;
  padding: 10px;
  font-size: 1em;
`;
const InputTextareaStyled = styled(InputTextarea)`
  outline: none !important;
  min-height: fit-content;
  height: auto;
  width: 100%;
  border: none;
  resize: none; /* Optional: prevent manual resizing */

  &::placeholder {
    color: var(--text-color);
    opacity: 0.5;
  }

  &:hover {
    background: var(--light-bg);
  }

  &:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: none !important;
    background: var(--light-bg);
    border-color: none !important;
  }
`;

function InstagramPostForm<FormDataType extends FieldValues>({
  name,
  index,
}: {
  name: Path<FormDataType>;
  index?: number;
}) {
  const {
    getValues,
    control,
    formState: { errors },
  } = useFormContext();

  const [expandedCommentIndex, setExpandedCommentIndex] = useState<
    number | null
  >(null);

  const { posts = [] } = useWatch({ control }); // Ensuring that posts has a default value

  const textareaRef = useRef<HTMLTextAreaElement | null>(null);
  const [imageUrlError, setImageUrlError] = useState("");

  const autoResizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    autoResizeTextarea(); // Initial adjustment on mount or when post content changes
  }, [posts]);

  const msgs = useRef<Messages>(null);

  const [errorMsg, setErrorMsg] = useState("");

  return (
    <div className="grid m-0">
      <div className="col-6 pl-4 pr-4 pt-4">
        <CardTitle title="פרסום פוסט באינסטגרם" />

        <div className="post p-2 mb-2">
          <LabelStyled>תוכן הפוסט</LabelStyled>
          <TextEditor name={name} />
          {/* <LabelStyled className="mt-2">הוסף מיקום</LabelStyled>
          <LocationDropdown name={`${name}.location`} /> */}
        </div>
        <div className="media p-2 mt-0 ">
          <LabelStyled>הוספת מדיה</LabelStyled>
          {/* <AttachmentInput<any> name={`${name}.imageUrl`} /> */}
          <MultyAttachmentInput
            name={`${name}.imageUrls`}
            validate={(value) => {
              const errorText = instagramPostMediaValidation(value);
              setImageUrlError(errorText);
              if (errorText === "") {
                return true;
              }

              return errorText;
            }}
          />
          {errorMsg != "" && <Message severity="warn" text={errorMsg} />}

          <span>{imageUrlError}</span>
        </div>
      </div>
      {/* <div className="col-3">
        <CommentSection>
          <CommentInputWrapper>
            <div className="flex align-items-center">
              <AvatarStyled label={"S"} shape="circle" className="ml-2" />
              <CommentInputCollapsed
                placeholder="רשום תגובה..."
                onFocus={() => setExpandedCommentIndex(0)}
              />
            </div>
            {expandedCommentIndex === 0 ? (
              <>
                <PostButton bgColor="purple" primary>
                  הוסף תגובה
                </PostButton>
              </>
            ) : (
              <></>
            )}
            <SingleCommentWrapper>
              <div className="flex align-items-center gap-10">
                <AvatarStyled label={"S"} shape="circle" className="" />
                <CommentUser>shimi</CommentUser>
                <CommentDate>24/1/24 15:32</CommentDate>
              </div>
              <Comment>אהבתי מאוד</Comment>
            </SingleCommentWrapper>
          </CommentInputWrapper>
        </CommentSection>
      </div> */}
      <div className="col-6 p-0">
        <ImageWrapper className="p-4">
          <IgPreview name={name} />
        </ImageWrapper>
      </div>
    </div>
  );
}

export default InstagramPostForm;
