import { PostEntityType } from "neword-core";
import { PostData } from "neword-core/dist/cjs/entities/post/postData";

export const createPostDeafultParams = (postType: PostEntityType): PostData => {
  switch (postType) {
    case PostEntityType.FACEBOOK_POST:
      return {
        type: "FACEBOOK_POST",
        message: "",
        published: false,
        imageUrls: [],
      };

    case PostEntityType.INSTAGRAM_POST:
      return {
        type: "INSTAGRAM_POST",
        media_type: "IMAGE",
        media_ids: [],
        message: "",
        imageUrls: [],
      };
    case PostEntityType.LINKEDIN_POST:
      return {
        type: "LINKEDIN_POST",
        message: "",
        imageUrls: [],
      };

    case PostEntityType.TIKTOK_POST:
      return {
        type: "TIKTOK_POST",
        message: "",
        imageUrls: [],
      };
    case PostEntityType.INSTAGRAM_REEL:
      return {
        type: "INSTAGRAM_REEL",
        message: "",
        imageUrls: [],
      };
    case PostEntityType.FACEBOOK_REEL:
      return {
        type: "FACEBOOK_REEL",
        message: "",
        imageUrls: [],
      };
    default:
      return {
        type: "FACEBOOK_POST",
        message: "",
        published: false,
        imageUrls: [],
      };
  }
};
