import { ReactComponent as IgIcon } from "../../../assets/Icons/InstagramIcon.svg";
import { ReactComponent as Facebook } from "../../../assets/Icons/facebook/FacebookLogo.svg";
import { ReactComponent as Linkedin } from "../../../assets/Icons/linkedin/LinkedInLogo.svg";
import { ReactComponent as TiktokLogo } from "../../../assets/Icons/TiktokLogo.svg";
import { ReactComponent as ReelsIcon } from "../../../assets/Icons/ReelsIcon.svg";
import { ReactComponent as Twitter } from "../../../assets/Icons/XLogo.svg";

const PlatformPostTypes = [
  {
    platform: "פוסט לאינסטגרם",
    content: "צרו פוסט, קרוסלה לאינסטגרם",
    icon: IgIcon,
  },
  {
    platform: "פוסט לפייסבוק",
    content: "צרו פוסט, קרוסלה, וידאו לפייסבוק",
    icon: Facebook,
  },
  {
    platform: "פוסט ללינקדאין",
    content: "צרו פוסט, קרוסלה ללינקדאין",
    icon: Linkedin,
  },
  {
    platform: "ריל לאינסטגרם",
    content: "צרו ריל לאינסטגרם",
    icon: ReelsIcon,
  },
  {
    platform: "ריל לפייסבוק",
    content: "צרו ריל לפייסבוק",
    icon: Facebook,
  },
  {
    platform: "סרטון לטיקטוק",
    content: "צרו סרטון לטיקטוק",
    icon: TiktokLogo,
  },
  {
    platform: "ציוץ לX (טוויטר)",
    content: "צרו סרטון לטיקטוק",
    icon: Twitter,
  },
];

export default PlatformPostTypes;