import { z } from "zod";

// Base media schema
const BaseMediaSchema = z.object({
  type: z
    .enum(["IMAGE", "VIDEO"])
    .describe("The type of the media: IMAGE or VIDEO"),
  size: z.number().positive().describe("The file size in bytes"),
});

// Image-specific schema
const ImageMediaSchema = BaseMediaSchema.extend({
  type: z.literal("IMAGE"),
  aspectRatio: z.string().describe("Aspect ratio of the image"),
  resolution: z.string().describe("Resolution of the image (e.g., 1080x1080)"),
});

// Video-specific schema
const VideoMediaSchema = BaseMediaSchema.extend({
  type: z.literal("VIDEO"),
  aspectRatio: z.string().describe("Aspect ratio of the video"),
  resolution: z
    .string()
    .describe("Resolution: recommended 1080x1920, minimum 540x960 pixels"),
  duration: z.number().describe("Duration in seconds: 3 to 90 seconds"),
  frameRate: z.number().describe("Frame rate: 24 to 60 frames per second"),
});

// Discriminated union schema
export const MetadataSchema = z.discriminatedUnion("type", [
  ImageMediaSchema,
  VideoMediaSchema,
]);

export const mediaSchema = z.object({
  url: z.string(),
  metadata: MetadataSchema,
});

// TypeScript types
export type MediaMetadata = z.infer<typeof MetadataSchema>;
